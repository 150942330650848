import allWords from "../assets/words.json";

import anagramWords from "../assets/anagrams.json";
import { IWord } from "../providers/anagram-provider";

let lastAnagram: string = "";

export function getNewAnagram(maxWordLength: number) {
  let anagram = findNewAnagram(maxWordLength);
  while (lastAnagram === anagram) {
    anagram = findNewAnagram(maxWordLength);
  }
  lastAnagram = anagram;
  return anagram;
}

function findNewAnagram(maxWordLength: number): string {
  const randomIndex = Math.floor(Math.random() * anagramWords.anagrams.length);
  const randomWord = anagramWords.anagrams[randomIndex];

  if (randomWord.length > maxWordLength) {
    return findNewAnagram(maxWordLength);
  }

  return randomWord;
}

export function getSubWords(count: number, maxWordLength: number): IWord[] {
  const anagram = getNewAnagram(maxWordLength);
  const subWord = anagram.split("").sort().join("");
  const results = [];

  const matchingWords = getMatchingWords(anagram);

  if (matchingWords.length < count - 1) {
    return getSubWords(count, maxWordLength);
  }

  while (results.length < count) {
    const randoSubWord = getRandomSubWord(subWord, matchingWords);

    if (results.indexOf(randoSubWord) === -1) {
      results.push(randoSubWord);
    }
  }

  // sort the words alphabetically, then by length, shortest first
  results.sort().sort((a, b) => {
    return a.length === b.length ? 0 : a.length > b.length ? 1 : -1;
  });

  // add the anagram last
  results.push(anagram);

  return results.map<IWord>((word) => {
    return {
      word,
      found: false,
      hint: {},
    };
  });
}

export function getMatchingWords(subWord: string): string[] {
  const allWordsLength = allWords.words.length;
  let i = 0;
  const matchingWords = [];
  while (i < allWordsLength) {
    const randomWord = allWords.words[i++];
    if (randomWord.length <= subWord.length && randomWord !== subWord) {
      const splitWord = randomWord.split("");
      const subWordClone = subWord.split("");

      while (splitWord.length > 0) {
        const idx = subWordClone.indexOf(splitWord[0]);
        if (idx !== -1) {
          subWordClone.splice(idx, 1);
          splitWord.splice(0, 1);
          if (splitWord.length === 0) {
            matchingWords.push(randomWord);
          }
        } else {
          break;
        }
      }
    }
  }

  return matchingWords;
}

function getRandomSubWord(subWord: string, matchingWords: string[]): string {
  const randomIndex = Math.floor(Math.random() * matchingWords.length);

  const word = matchingWords[randomIndex];

  matchingWords.splice(randomIndex, 1);

  return word;
}

export function randomizeLetters(word: string = ""): string[] {
  let randomLetters = randomizeWordLetters(word);

  // make sure that it isn't randomly the original anagram
  while (randomLetters === word) {
    randomLetters = randomizeWordLetters(word);
  }

  return randomLetters.split("");
}

function randomizeWordLetters(word: string): string {
  // randomize the letters in 'word'
  const randoWord = word
    .split("")
    .sort(() => {
      return 0.5 - Math.random();
    })
    .join("");

  // get random true/false value
  const rando = Math.random() >= 0.5;

  return rando ? randoWord : randoWord.split("").reverse().join("");
}

export function isWord(word: string): boolean {
  return allWords.words.includes(word);
}
