import React from "react";
import { useAnagramsContext } from "../providers/anagram-provider";

export default function AppNavigation(props: any) {
  const anagramsContext = useAnagramsContext();
  function getNewAnagrams() {
    anagramsContext?.getNewAnagrams();
  }
  function getAClue() {
    anagramsContext?.getAClue();
  }
  return (
    <>
      <menu>
        <ul>
          <li
            onClick={() => {
              getNewAnagrams();
            }}
          >
            New Word Sets
          </li>

          <li>
            <span
              onClick={() => {
                anagramsContext?.hintCount > 0 && getAClue();
              }}
            >
              Get a Clue ({anagramsContext?.hintCount})
            </span>
          </li>
          <li>
            <span
              onClick={() => {
                console.log("showTheHelpFile");
                props.setShowTheHelp(true);
              }}
            >
              Help!
            </span>
          </li>
        </ul>
      </menu>
    </>
  );
}
