import React from "react";
import { IWord, useAnagramsContext } from "../providers/anagram-provider";

import WordHolder from "./word-holder/word-holder";

import "./word-list.scss";

function getWordList(anagrams: IWord[]) {
  return (
    <ul>
      {anagrams.map((word: IWord, idx) => {
        return (
          <li key={idx} className={word.found ? "found" : ""}>
            <WordHolder word={word}></WordHolder>
          </li>
        );
      })}
    </ul>
  );
}

function WordList() {
  const anagramsContext = useAnagramsContext();
  return (
    <div className="words">
      <h2>Words</h2>
      {getWordList(anagramsContext.anagrams)}
    </div>
  );
}

export default WordList;
