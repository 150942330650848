import React, { useEffect, useState } from "react";

import { useAnagramsContext } from "../providers/anagram-provider";
import { useScoreContext } from "../providers/scores-provider";

import "./score.scss";

function Score() {
  const { level, gamesWon } = useAnagramsContext();
  const { scores } = useScoreContext();

  const [wordPulse, setWordPulse] = useState(false);
  const [bonusWordPulse, setBonusWordPulse] = useState(false);

  useEffect(() => {
    setWordPulse(true);
    setTimeout(() => {
      setWordPulse(false);
    }, 500);
  }, [scores.wordScore]);

  useEffect(() => {
    setBonusWordPulse(true);
    setTimeout(() => {
      setBonusWordPulse(false);
    }, 500);
  }, [scores.bonusScore]);

  return (
    <div className="scoring-panel">
      <table className="stats">
        <tbody>
          <tr>
            <td>Level</td>
            <td>{level}</td>
          </tr>
          <tr>
            <td>Won</td>
            <td>{gamesWon}</td>
          </tr>
        </tbody>
      </table>

      <table className="score">
        <thead>
          <tr>
            <td colSpan={2} style={{ textAlign: "center" }}>
              Score
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="label word">Word Score</td>
            <td className={wordPulse ? "pulse" : ""}>
              {scores.wordScore.toLocaleString()}
            </td>
          </tr>
          <tr>
            <td className="label bonus">Bonus Score</td>
            <td className={bonusWordPulse ? "pulse" : ""}>
              {scores.bonusScore.toLocaleString()}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td className="label total">Total</td>
            <td>{scores.totalScore.toLocaleString()}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default Score;
