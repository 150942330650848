import "./word-holder.css";
import { IWord } from "../../providers/anagram-provider";

function getLetterTiles(
  letters: string = "",
  found: boolean,
  hint: { [key: number]: boolean } = {}
) {
  return letters
    .toUpperCase()
    .split("")
    .map((letter, idx) => {
      return (
        <div key={idx} className="tile">
          {found || hint[idx] === true ? letter : ""}
        </div>
      );
    });
}

function WordHolder(props: { word: IWord }) {
  const { word, found, hint } = props.word;
  return (
    <div className={"word-holder " + (found ? "found" : "")}>
      {getLetterTiles(word, found, hint)}
    </div>
  );
}

export default WordHolder;
