import React, { useState, useContext, useEffect, useMemo } from "react";
import { getSubWords, randomizeLetters } from "../utilities";

import allWords from "../assets/words.json";
import { levelBreakPoints } from "../config";

export interface IWord {
  word: string;
  found: boolean;
  hint: { [key: number]: boolean };
}

interface IAnagramContext {
  letters: string[];
  anagrams: IWord[];
  foundWords: string[];
  bonusWords: string[];
  hintCount: number;
  winner: boolean;
  level: number;
  gamesWon: number;
  getNewAnagrams: () => void;
  checkWord: (word: string) => void;
  resetWinner: () => void;
  getAClue: () => void;
  shuffleTheLetters: () => void;
}

const defaultState: IAnagramContext = {
  letters: [],
  anagrams: [],
  foundWords: [],
  bonusWords: [],
  hintCount: 2,
  winner: false,
  level: 0,
  gamesWon: 0,
  getNewAnagrams: () => {
    console.log("Not actually generating new anagrams.");
  },
  checkWord: (word: string) => {
    console.log("Not actually checking the word.");
  },
  resetWinner: () => {
    console.log("Reset winner not actually resetting the winner");
  },
  getAClue: () => {
    console.log("Not actually getting a clue");
  },
  shuffleTheLetters: () => {
    console.log("Not actually shuffling the letters");
  },
};

const AnagramsContext = React.createContext<IAnagramContext>(defaultState);

export function AnagramsProvider(props: { children: any }) {
  const startingLevel = useMemo(() => {
    const localStoreLevel = localStorage.getItem("level");
    if (localStoreLevel) return parseInt(localStoreLevel);
    return 0;
  }, []);

  const startingGamesWon = useMemo(() => {
    const localStoreGamesWon = localStorage.getItem("gamesWon");
    if (localStoreGamesWon) return parseInt(localStoreGamesWon);
    return 0;
  }, []);

  const startingAnagrams = useMemo<IWord[]>(() => {
    return getSubWords(
      levelBreakPoints[0].words,
      levelBreakPoints[0].maxWordLength
    );
  }, []);

  const [letters, setLetters] = useState<string[]>([]);
  const [gamesWon, setGamesWon] = useState(startingGamesWon);
  const [level, setLevel] = useState(startingLevel);
  const [anagrams, setAnagrams] = useState<IWord[]>(startingAnagrams);
  const [bonusWords, setBonusWords] = useState<string[]>([]);
  const [hintCount, setHintCount] = useState<number>(2);
  const [winner, setWinner] = useState<boolean>(false);
  const [foundWords, setFoundWords] = useState<string[]>([]);

  useEffect(() => {
    if (letters.length === 0) {
      setLetters(randomizeLetters(anagrams[anagrams.length - 1].word));
    }
  }, [anagrams, gamesWon, hintCount, letters]);

  function getNewAnagrams() {
    const newWords: IWord[] = getSubWords(
      levelBreakPoints[level].words,
      levelBreakPoints[level].maxWordLength
    );

    setFoundWords([]);
    setAnagrams(newWords);
    setLetters(newWords[newWords.length - 1].word.split(""));
  }

  // check the level
  useEffect(() => {
    levelBreakPoints.forEach((brkPnt) => {
      if (gamesWon >= brkPnt.minGamesWon && gamesWon <= brkPnt.maxGamesWon) {
        const newLevel = Math.min(brkPnt.level, levelBreakPoints.length - 1);
        setLevel(newLevel);
        localStorage.setItem("level", `${newLevel}`);
      }
    });
  }, [gamesWon]);

  function checkWord(word: string) {
    // only add things once :D
    if (
      !foundWords.includes(word) &&
      anagrams.map((a) => a.word).includes(word)
    ) {
      const updatedAnagrams = [...anagrams];

      updatedAnagrams.forEach((anagram) => {
        if (anagram.word === word) {
          anagram.found = true;
        }
      });
      setFoundWords([...foundWords, word]);
      setAnagrams(updatedAnagrams);

      if (updatedAnagrams.every((anagram) => anagram.found)) {
        setWinner(true);
        localStorage.setItem("gamesWon", `${gamesWon + 1}`);
        setGamesWon(gamesWon + 1);
        if (gamesWon % 3 === 0) {
          setHintCount(hintCount + 1);
        }
      }
    } else if (!bonusWords.includes(word) && allWords.words.includes(word)) {
      console.log("setBonusWords added a word");
      setBonusWords([...bonusWords, word]);
    }
  }

  function getAClue() {
    // find the first word that hasn't been found
    const unfoundWord = anagrams.find(
      (anagram) => !foundWords.some((fw) => fw === anagram.word)
    );
    if (unfoundWord) {
      // find the first letter that hasn't been found
      const unfoundLetter: number | undefined = unfoundWord.word
        .split("")
        .findIndex((letter, idx) => {
          return unfoundWord.hint[idx] !== true;
        });
      if (typeof unfoundLetter !== "undefined" && unfoundLetter > -1) {
        // set the hint for that letter to true
        unfoundWord.hint[unfoundLetter] = true;
        setHintCount(hintCount - 1);
        const updatedAnagrams = [...anagrams];
        updatedAnagrams.forEach((anagram) => {
          if (anagram.word === unfoundWord.word) {
            anagram.hint = unfoundWord.hint;
          }
        });
        setAnagrams(updatedAnagrams);
      }
    }
  }

  function resetGame() {
    getNewAnagrams();
    setFoundWords([]);
    setBonusWords([]);
    setWinner(false);
  }

  function shuffleTheLetters() {
    const newLetters = randomizeLetters(letters.join(""));
    if (letters.length > 4 && letters.join("") === newLetters.join("")) {
      shuffleTheLetters();
      return;
    }
    setLetters(newLetters);
  }

  return (
    <AnagramsContext.Provider
      value={{
        letters,
        anagrams,
        foundWords,
        bonusWords,
        hintCount,
        level,
        gamesWon,
        winner,
        getNewAnagrams,
        checkWord,
        resetWinner: resetGame,
        getAClue,
        shuffleTheLetters,
      }}
    >
      {props.children}
    </AnagramsContext.Provider>
  );
}

export const useAnagramsContext = () => useContext(AnagramsContext);
