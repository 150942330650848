import React from "react";
import { useAnagramsContext } from "../providers/anagram-provider";

import "./winner.scss";

export function Winner() {
  const anagramsContext = useAnagramsContext();

  function closeTheWinnerWinnerChickenDinnerDialog() {
    anagramsContext.resetWinner();
  }

  return (
    <>
      {anagramsContext.winner && (
        <>
          <div id="silkScreen"></div>
          <div id="winnerWinnerChickenDinner">
            <div className="message">You Cleared the Bored!</div>
            <div
              className="button"
              onClick={closeTheWinnerWinnerChickenDinnerDialog}
            >
              Shall We Play Again?
            </div>
          </div>
        </>
      )}
    </>
  );
}
