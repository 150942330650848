import React, { useEffect, useState } from "react";

import LetterPicker from "./letter-picker/letter-picker";
import Score from "./score/score";
import WordList from "./word-list/word-list";

import "./App.scss";

import { ScoresProvider } from "./providers/scores-provider";
import { AnagramsProvider } from "./providers/anagram-provider";
import AppNavigation from "./app-navigation";
import { Winner } from "./winner";
import { Help } from "./help/help";

declare global {
  interface Window {
    anagrams: string[];
  }
}

function App() {
  const [showTheHelp, setShowTheHelp] = useState(false);

  useEffect(() => {
    console.log("showTheHelpFile?", showTheHelp);
  }, [showTheHelp]);

  return (
    <>
      <AnagramsProvider>
        <ScoresProvider>
          <div className="App">
            <header>
              <div
                style={{
                  display: "inline-block",
                  float: "left",
                  marginRight: "4px",
                }}
              >
                <a href="https://anaga.me">
                  <img className="logo" src="logo-narrow.jpeg" />
                </a>
              </div>
              <h1>Anaga.me</h1>
              <h2>&quot;It's a word, it's a panel!&quot;</h2>
              <AppNavigation setShowTheHelp={setShowTheHelp} />
            </header>

            <section className="words-and-score">
              <WordList />
              <div className="score">
                <Score></Score>
              </div>
            </section>
            <section className="tiles">
              <LetterPicker />
            </section>
            <Winner />
          </div>
        </ScoresProvider>
      </AnagramsProvider>
      {showTheHelp && (
        <div className="helpContainer">
          <div
            className="theCloser"
            onClick={() => {
              setShowTheHelp(false);
            }}
          >
            X
          </div>
          <Help />
        </div>
      )}
    </>
  );
}

export default App;
