import React from "react";

import "./help.scss";

export function Help() {
  return (
    <div className="help">
      <h1>Help!</h1>
      <h2>What's Anaga.me</h2>
      <p>
        Anaga.me is an anagram game. We've compiled a long list of anagrams. One
        will be picked at random (depending on what level you're on the length
        will change), and then other words will be chosen from a jumble of the
        anagram's letters.
      </p>
      <h2>How to Play!</h2>
      <p>Game play is pretty simple:</p>
      <ol>
        <li>
          Look at the letter boxes at the top of the Words section of Anaga.me.
          These are clues! If there are 3 words represented that are 3 letters
          long but you can think of 5 you'll get bonus points for the words not
          in the list!
        </li>
        <li>
          Swipe your finger or mouse across the letters on the circle below the
          Words section. The order you swipe through the letters will be the
          word you build. Once you release the mouse click or lift your finger
          the word will be checked. If it's not a word, don't worry, keep going!
        </li>
      </ol>
      <h3>Scoring!</h3>
      <p>
        If the word you swiped is one of the words in the random list [or the
        anagram] you'll get 5 points per letter added to your Word Score. If the
        word is not one of the random words, but exists in our dictionary you'll
        get a point for each letter in the word [we know your dictionary is
        different and has that one word. Sorry!]
      </p>
      <h3>I'm Stuck!</h3>
      <p>
        If you get stuck and can't figure out any remaining words you can just
        click the 'New Word Sets' link in the header. You won't win the game,
        but you'll get a fresh start.
      </p>
      <h2>We Use Local Storage</h2>
      <p>
        If you came back to Anaga.me and notice that your level, games won, and
        score were preserved that's because we use your browser's local storage
        to keep just those pieces of information.
      </p>
      <p>
        We don't use cookies, we don't trace you, we just want you to play
        Anaga.me.
      </p>
    </div>
  );
}
