export function Letter(props: {
  letter: string;
  idx: number;
  isSelected: boolean;
  onMouseEnter: Function;
  onMouseDown: Function;
  onMouseUp: Function;
}) {
  return (
    <div
      className={"letter " + (props.isSelected ? "selected" : "")}
      key={props.idx}
      data-letter={props.letter}
      data-idx={props.idx}
      onMouseEnter={($evt: React.MouseEvent) => {
        props.onMouseEnter($evt, props.letter, props.idx);
      }}
      onMouseDown={($evt: React.MouseEvent) => {
        props.onMouseDown($evt, props.letter, props.idx);
      }}
    >
      <span>{props.letter}</span>
    </div>
  );
}
