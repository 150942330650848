type LevelBreakPoint = {
  level: number;
  minGamesWon: number;
  maxGamesWon: number;
  words: number;
  maxWordLength: number;
};

export const levelBreakPoints: LevelBreakPoint[] = [
  {
    level: 1,
    minGamesWon: 0,
    maxGamesWon: 4,
    words: 4,
    maxWordLength: 5,
  },
  {
    level: 2,
    minGamesWon: 5,
    maxGamesWon: 9,
    words: 5,
    maxWordLength: 5,
  },
  {
    level: 3,
    minGamesWon: 10,
    maxGamesWon: 19,
    words: 6,
    maxWordLength: 6,
  },
  {
    level: 4,
    minGamesWon: 20,
    maxGamesWon: 29,
    words: 7,
    maxWordLength: 7,
  },
  {
    level: 5,
    minGamesWon: 30,
    maxGamesWon: 39,
    words: 8,
    maxWordLength: 7,
  },
  {
    level: 6,
    minGamesWon: 40,
    maxGamesWon: 49,
    words: 9,
    maxWordLength: 8,
  },
  {
    level: 7,
    minGamesWon: 50,
    maxGamesWon: 59,
    words: 10,
    maxWordLength: 8,
  },
  {
    level: 8,
    minGamesWon: 60,
    maxGamesWon: 69,
    words: 11,
    maxWordLength: 9,
  },
  {
    level: 9,
    minGamesWon: 70,
    maxGamesWon: 79,
    words: 12,
    maxWordLength: 10,
  },
  {
    level: 10,
    minGamesWon: 80,
    maxGamesWon: 89,
    words: 13,
    maxWordLength: 10,
  },
  {
    level: 11,
    minGamesWon: 90,
    maxGamesWon: 99,
    words: 14,
    maxWordLength: 15,
  },
  {
    level: 12,
    minGamesWon: 100,
    maxGamesWon: 99999999,
    words: 15,
    maxWordLength: 20,
  },
];
