import React, { useState, useContext, useEffect, useMemo } from "react";
import { useAnagramsContext } from "./anagram-provider";

interface IScoreSet {
  bonusScore: number;
  totalScore: number;
  wordScore: number;
}

interface IScoreContext {
  scores: IScoreSet;
  setScores?: (scores: IScoreSet) => void;
}

const defaultState: IScoreContext = {
  scores: { bonusScore: 0, totalScore: 0, wordScore: 0 },
};

const ScoresContext = React.createContext<IScoreContext>(defaultState);

function listDiff(l1: string[], l2: string[]) {
  const diff = [...l1];
  l2.forEach((word) => {
    const indexOfWord = diff.indexOf(word);
    if (indexOfWord > -1) {
      diff.splice(indexOfWord, 1);
    }
  });
  return diff;
}

export function ScoresProvider(props: { children: any }) {
  const localStorage = window.localStorage;

  const startingScore = useMemo(() => {
    const localStoreScore = localStorage.getItem("scores");
    if (localStoreScore) {
      return JSON.parse(localStoreScore);
    }

    return {
      bonusScore: 0,
      totalScore: 0,
      wordScore: 0,
    };
  }, []);

  const anagramsContext = useAnagramsContext();

  const [foundWords, setFoundWords] = useState<string[]>([]);

  const [bonusWords, setBonusWords] = useState<string[]>([]);

  const [scores, setScores] = useState(startingScore);

  // found words handler
  useEffect(() => {
    const diff = listDiff(anagramsContext.foundWords, foundWords);

    let totalScore = scores.totalScore;
    let wordScore = diff.reduce((wordScore, word) => {
      wordScore += word.length * 5;
      totalScore += word.length * 5;
      return wordScore;
    }, 0);

    setScores({
      bonusScore: scores.bonusScore,
      wordScore: scores.wordScore + wordScore,
      totalScore: totalScore,
    });
    setFoundWords(anagramsContext.foundWords);
  }, [anagramsContext.foundWords, foundWords]);

  useEffect(() => {
    const diff = listDiff(anagramsContext.bonusWords, bonusWords);
    let totalScore = scores.totalScore;
    let bonusScore = diff.reduce((bonusScore, word) => {
      bonusScore += word.length * 2;
      totalScore += word.length * 2;
      return bonusScore;
    }, 0);

    setScores({
      wordScore: scores.wordScore,
      bonusScore: scores.bonusScore + bonusScore,
      totalScore: totalScore,
    });
    setBonusWords(anagramsContext.bonusWords);
  }, [anagramsContext.bonusWords, bonusWords]);

  useEffect(() => {
    setFoundWords([]);
    setBonusWords([]);
  }, [anagramsContext.winner]);

  useEffect(() => {
    localStorage.setItem("scores", JSON.stringify(scores));
  }, [scores]);

  const updateScores = (scores: IScoreSet) => {
    setScores(scores);
  };

  return (
    <ScoresContext.Provider value={{ scores, setScores: updateScores }}>
      {props.children}
    </ScoresContext.Provider>
  );
}

export const useScoreContext = () => useContext(ScoresContext);
