import React, { useEffect, useState } from "react";
import { useAnagramsContext } from "../providers/anagram-provider";

import "./letter-picker.scss";
import { Letter } from "./letter";

type SelectedLetter = {
  idx: number;
  letter: string;
  order: number;
};

function LetterPicker() {
  const anagramsContext = useAnagramsContext();

  const { letters } = anagramsContext;

  const [selectedLetters, setSelectedLetters] = useState<SelectedLetter[]>([]);

  const [lettersLengthClass, setLettersLengthClass] = useState<string>("four");

  useEffect(() => {
    if (letters.length === 0) {
      return;
    }

    setLettersLengthClass(getLetterCountClass(letters.length));
  }, [letters]);

  const [isMouseDown, setIsMouseDown] = useState(false);

  function registerDocumentUpListeners() {
    document.addEventListener("mouseup", stopListening);
    document.addEventListener("mouseleave", stopListening);
  }

  function stopListening() {
    document.removeEventListener("mouseup", stopListening);
    document.removeEventListener("mouseleave", stopListening);

    checkWord();
    setSelectedLetters([]);

    setIsMouseDown(false);
  }

  function onMouseDown(
    $evt: React.MouseEvent | React.TouchEvent,
    letter: string | null = null,
    idx: number | null = null
  ) {
    setIsMouseDown(true);
    registerDocumentUpListeners();
    if (letter !== null && idx !== null)
      setSelectedLetters([
        {
          idx,
          letter: letter,
          order: 0,
        },
      ]);
  }

  function onMouseUp($evt: React.MouseEvent | React.TouchEvent) {
    checkWord();
    setSelectedLetters([]);
    setIsMouseDown(false);
  }

  function onMouseEnter($evt: any, letter: string, idx: number): void {
    // if it has been selected, don't select it again
    if (!isMouseDown || selectedLetters.some((letter) => letter.idx === idx)) {
      return;
    }

    setSelectedLetters([
      ...selectedLetters,
      {
        idx,
        letter: letter,
        order: selectedLetters.length,
      },
    ]);
  }

  function checkWord() {
    if (selectedLetters.length === 0) {
      return;
    }

    const wordish = selectedLetters
      .map((selectedLetter) => selectedLetter.letter)
      .join("");
    anagramsContext.checkWord(wordish);
  }

  function touchStartEmulator($evt: any) {
    const touch = $evt.touches[0];
    const el = document.elementFromPoint(touch.clientX, touch.clientY);
    if (el?.className.indexOf("letter") !== -1) {
      const letter = el?.getAttribute("data-letter") ?? "";
      const idx = el?.getAttribute("data-idx") ?? "-1";
      onMouseDown($evt, letter, parseInt(idx));
    } else if (el.className.indexOf("hold-all-the-things") !== -1) {
      setIsMouseDown(true);
      registerDocumentUpListeners();
    }
  }

  function touchMoveEmulator($evt: any) {
    const touch = $evt.touches[0];
    const el = document.elementFromPoint(touch.clientX, touch.clientY);
    if (el?.className.indexOf("letter") !== -1) {
      const letter = el?.getAttribute("data-letter") ?? "";
      const idx = el?.getAttribute("data-idx") ?? "-1";
      onMouseEnter($evt, letter, parseInt(idx));
    }
  }

  function touchEndEmulator($evt: any) {
    onMouseUp($evt);
  }

  return (
    <>
      {letters && letters.length > 0 && (
        <div
          className="hold-all-the-things"
          onTouchStart={touchStartEmulator}
          onTouchMove={touchMoveEmulator}
          onTouchEnd={touchEndEmulator}
        >
          <div className="shuffle-the-letters">
            <div
              onClick={(evt) => {
                evt.preventDefault();
                anagramsContext.shuffleTheLetters();
              }}
            >
              &#9852;
            </div>
          </div>
          <div
            className={"circle-holder " + lettersLengthClass}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          >
            {getLetterList(
              letters.join(""),
              selectedLetters,
              onMouseEnter,
              onMouseDown,
              onMouseUp
            )}
          </div>
          <div className="selectedLetters">
            {selectedLetters.map((l) => l.letter.toUpperCase()).join("")}
          </div>
        </div>
      )}
      {(!letters || letters.length === 0) && <div>Oofda</div>}
    </>
  );
}

function getLetterList(
  letters: string,
  selectedLetters: any,
  onMouseEnter: any,
  onMouseDown: any,
  onMouseUp: any
) {
  return letters
    .split("")
    .map((letter, idx) => (
      <Letter
        letter={letter}
        key={idx}
        idx={idx}
        isSelected={selectedLetters.some(
          (selectedLetter: any) => selectedLetter.idx === idx
        )}
        onMouseEnter={onMouseEnter}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      />
    ));
}

function getLetterCountClass(lettersLength: number) {
  switch (lettersLength) {
    case 4:
      return "four";
    case 5:
      return "five";
    case 6:
      return "six";
    case 7:
      return "seven";
    case 8:
      return "eight";
    case 9:
      return "nine";
    case 10:
      return "ten";
    case 11:
      return "eleven";
    case 12:
      return "twelve";
    case 13:
      return "thirteen";
    case 14:
      return "fourteen";
    case 15:
      return "fifteen";
    default:
      console.log(
        "really, we have a problem with the length of the string",
        lettersLength
      );
      return "four";
  }
}

export default LetterPicker;
